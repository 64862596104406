import { CardError, List, ListItem, LoadBar, Checkbox } from 'components'
import { ButtonBlock } from '@politechdev/blocks-design-system'
import { useEffect, useContext } from 'react'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { useRequest } from 'hooks'
import {
  doesFlagHaveUnreadComments,
  getFlagItemPrimaryText,
  getFlagItemSecondaryText,
  isFlagTechnical,
} from './utils'
import { ListPager } from './ListPager'
import ReviewListIconSet from './ReviewListIconSet/ReviewListIconSet'
import ViewFlagButton from './buttons/ViewFlagButton'
import DeleteFlagButton from './buttons/DeleteFlagButton'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'
import TableProgramBulkReview from './TableProgramBulkReview'

const FlagListItemAction = ({ flag, typeOverride, reloadFlags }) => {
  const { unassignPacket } = usePacketAssignment()
  return (
    <ButtonBlock>
      <ViewFlagButton flagId={flag.id} typeOverride={typeOverride} />
      <DeleteFlagButton
        onDelete={async () => {
          if (isFlagTechnical(flag)) {
            await unassignPacket(flag.packet)
          }
          reloadFlags()
        }}
        flag={flag}
      />
    </ButtonBlock>
  )
}

const TableProgramReviewList = ({
  setListPage,
  checkboxContext,
  hideCheckbox,
  BlankSlate,
  tabType,
}) => {
  const { isChecked, toggleCheckbox, clearCheckboxes, setCheckboxData } =
    useContext(checkboxContext)
  const { currentUser } = useCurrent()

  const { filters, listPages, fetchTableFlags, fetchResolutionFlags } =
    useFlagsFilteredListContext()

  const fetchFlagAction =
    tabType === 'table' ? fetchTableFlags : fetchResolutionFlags

  const useFlagsRequest = useRequest(fetchFlagAction, {
    onSuccess: response => setCheckboxData(response['quality_control/flags']),
  })

  const currentPage = listPages[tabType]

  const flagsErrorMsg = formatErrorMessage(useFlagsRequest.errors)

  const reloadFlags = () => useFlagsRequest.makeRequest(filters, currentPage)

  useEffect(() => {
    clearCheckboxes()
    reloadFlags()
  }, [tabType, currentPage, filters, currentUser.id])

  const flags = useFlagsRequest.response?.['quality_control/flags'] || []
  const meta = useFlagsRequest.response?.meta
  const totalCount = meta?.total_count ?? 0
  const per = meta?.per ?? 0
  const totalPages =
    per === 0 || totalCount === 0 || totalCount < per
      ? 1
      : Math.ceil(totalCount / per)

  return (
    <>
      <TableProgramBulkReview
        checkboxContext={checkboxContext}
        tabType={tabType}
        refreshListData={reloadFlags}
      />
      <LoadBar show={useFlagsRequest.isLoading} />
      <CardError hide={!useFlagsRequest.hasErrors} message={flagsErrorMsg} />
      {useFlagsRequest.isRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-group-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={
                  <FlagListItemAction
                    flag={flag}
                    typeOverride={tabType === 'table' ? 'start' : 'complete'}
                    reloadFlags={reloadFlags}
                  />
                }
                Checkbox={
                  hideCheckbox ? undefined : (
                    <Checkbox
                      checked={isChecked(flag.id)}
                      onChange={() => toggleCheckbox(flag.id)}
                      id={`flag-checkbox-${flag.id}`}
                      name={`flag-checkbox-${flag.id}`}
                      aria-label="select column"
                      aria-labelledby={`flag-checkbox-${flag.id}`}
                    />
                  )
                }
                AdditionalContent={<ReviewListIconSet flag={flag} />}
              />
            ))}
          </List>
          <ListPager
            currentPage={currentPage}
            setPage={setListPage}
            totalPages={totalPages}
          />
        </>
      )}
      {useFlagsRequest.isRequestComplete && !flags.length && <BlankSlate />}
    </>
  )
}

export const PendingTableProgramReviewList = TableProgramReviewList
