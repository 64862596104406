import moment from 'moment'
import { formatDecimalCell } from 'utils/reporting'
import qcSummaryByRoleConfig from './reports/qcSummaryByRole'
import qcSummaryByUserConfig from './reports/qcSummaryByUser'
import qcFlagStatusConfig from './reports/qcFlagStatus'
import qcPhoneCallsStatusConfig from './reports/qcPhoneCallsStatus'
import qcPhoneCallsByUserConfig from './reports/qcPhoneCallsByUser'
import qcPacketsByDayConfig from './reports/qcPacketsByDay'
import qcScansByDayConfig from './reports/qcScansByDay'
import qcByResponseConfig from './reports/qcByResponse'
import dailyQcConfig from './reports/dailyQc'
import qcNotesByScanConfig from './reports/qcNotesByScan'
import vizByDay from './reports/vizByDay'
import phoneByDay from './reports/phoneByDay'
import qcFlagsByDate from './reports/qcFlagsByDate'
import { columnSummaryFunctions } from './columnTotals'
import { REGISTRATION_REPORTS_ALL_TIME_START_DATE } from '../constants'

export const reportCategories = [
  {
    id: 'qcFlags',
    name: 'Flags',
    reportTypes: ['qc_flag_status', 'qc_flags_by_date'],
    permissionId: 'qc_flags',
  },
  {
    id: 'qcWorkflow',
    name: 'QC Workflow',
    reportTypes: [
      'qc_summary_by_user',
      'qc_summary_by_role',
      'qc_scans_by_day',
      'qc_notes_by_scan',
    ],
    permissionId: 'qc_workflow',
  },
  {
    id: 'qcStaff',
    name: 'QC Staff',
    reportTypes: [
      'qc_phone_calls_status',
      'qc_phone_calls_by_user',
      'daily_qc',
      'viz_by_day',
      'phone_by_day',
      'qc_by_response',
      'qc_packets_by_day',
    ],
    permissionId: 'qc_staff',
  },
]

export const permissionIds = reportCategories.map(
  category => category.permissionId
)

const prepareReportTypes = () => {
  const reportConfigs = [
    qcByResponseConfig,
    dailyQcConfig,
    qcSummaryByUserConfig,
    qcSummaryByRoleConfig,
    qcFlagStatusConfig,
    qcPhoneCallsStatusConfig,
    qcPhoneCallsByUserConfig,
    qcPacketsByDayConfig,
    qcScansByDayConfig,
    qcNotesByScanConfig,
    qcFlagsByDate,
    vizByDay,
    phoneByDay,
  ]

  return reportConfigs.map(reportConfig => ({
    ...reportConfig,
    columns: reportConfig.columns.map(column => ({
      ...column,
      bodyCell:
        column.type === 'number' && !column.bodyCell
          ? formatDecimalCell
          : column.bodyCell,
      summaryFunction:
        column.summaryFunction || columnSummaryFunctions[column.dataKey],
    })),
  }))
}

const customDatePresets = [
  {
    label: 'Total',
    startDate: moment(REGISTRATION_REPORTS_ALL_TIME_START_DATE).toISOString(),
    endDate: moment().toISOString(),
  },
  {
    label: 'Year to Date',
    startDate: moment().startOf('year').toISOString(),
    endDate: moment().toISOString(),
  },
]

const defaultReportType = 'qc_summary_by_user'

export default {
  reportTypes: prepareReportTypes(),
  reportCategories,
  customDatePresets,
  defaultReportType,
}
