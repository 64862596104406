import {
  Button,
  ButtonBlock,
  FieldBlock,
  Font,
  Modal,
  MultiSelectField,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import {
  useDeliveryState,
  Delivery,
} from 'deliveries/DeliverySingle/DeliveryContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getEligibleFormIds,
  buildCountyOptions,
} from 'deliveries/DeliverySingle/utils'

const removeSelectedAndFormat = (
  countyOptions: Array<{ id: string; name: string }>,
  currentDelivery: Delivery
) =>
  countyOptions
    .filter(option => !currentDelivery.form_filter_counties.includes(option.id))
    .map(({ id, name }) => ({ label: name, value: id }))

const AddCountyModal = ({
  isOpen,
  setIsOpen,
  showLoading,
  handleContinue,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  showLoading: boolean
  handleContinue: (counties: string[]) => void | Promise<void>
}) => {
  const { t } = useTranslation()

  const { currentDelivery, pendingUpdates, packets } = useDeliveryState()

  const [selectedCounties, setSelectedCounties] = useState<string[]>([])

  const eligibleFormIds = getEligibleFormIds({
    delivery: currentDelivery,
    pendingUpdates,
    packets,
    searchTerm: '',
    options: {
      county: false,
    },
  }) as number[]

  const countyOptions = buildCountyOptions({ eligibleFormIds, packets })
  const cleanCountyOptions = removeSelectedAndFormat(
    countyOptions,
    currentDelivery
  )

  const onContinue = async () => {
    await handleContinue(selectedCounties)
    setSelectedCounties([])
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      title={t('Add more counties to delivery')}
      isOpen={isOpen}
      showLoading={showLoading}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t(
                'Adding more counties may bring in new packets that were previously not eligible for delivery in the selection step.'
              )}
            </Font.Copy>
            <Font.Copy Element="p">
              {t(
                'Counties cannot be removed from this delivery without reverting back to the selection step.'
              )}
            </Font.Copy>
          </TextBlock>
          <FieldBlock>
            <MultiSelectField
              label={t('Add counties')}
              values={selectedCounties}
              onSelect={value => setSelectedCounties(value as string[])}
              options={cleanCountyOptions}
              icon={undefined}
            />
          </FieldBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onContinue}>{t('Submit')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default AddCountyModal
