import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { Timestamp } from 'components/Time/Timestamp'
import { FormWithPacketInfo } from './types'

export const ScanCell = ({ rowData }: { rowData: FormWithPacketInfo }) => (
  <TableGridCell subtitle={undefined}>{rowData.scan_number}</TableGridCell>
)

export const PacketCell = ({ rowData }: { rowData: FormWithPacketInfo }) => (
  <TableGridCell subtitle={undefined}>
    {rowData?.packet?.scan_name}
  </TableGridCell>
)

export const RegistrantCell = ({
  rowData,
}: {
  rowData: FormWithPacketInfo
}) => <TableGridCell subtitle={undefined}>{rowData.display_name}</TableGridCell>

export const CountyCell = ({ rowData }: { rowData: FormWithPacketInfo }) => (
  <TableGridCell subtitle={undefined}>{rowData.county}</TableGridCell>
)

export const CollectedCell = ({ rowData }: { rowData: FormWithPacketInfo }) => (
  <TableGridCell subtitle={undefined}>
    {!!rowData.packet?.shift?.shift_start && (
      <Timestamp
        dateTime={rowData.packet.shift.shift_start}
        timezone={undefined}
      />
    )}
  </TableGridCell>
)

export const UploadedCell = ({ rowData }: { rowData: FormWithPacketInfo }) => (
  <TableGridCell subtitle={undefined}>
    {!!rowData.created_at && (
      <Timestamp dateTime={`${rowData.created_at}Z`} timezone={undefined} />
    )}
  </TableGridCell>
)
