import { useState, useRef, ReactNode, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import { UserMenu } from 'components'
import { Font, Icon } from '@politechdev/blocks-design-system'
import { routes } from 'layout/routesConfig'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import NavigationItem from './NavigationItem'
import NavigationBlock from './NavigationBlock'
import LanguageToggle from '../LanguageToggle/LanguageToggle'
import styles from './Navigation.module.scss'

type Block = 'organize' | 'quality control' | 'petitions' | 'registration'

export type Permission = {
  resource: string
  ability: string
}

export type Route = {
  [label: string]: {
    path?: string
    permissions?: Permission[]
    blocks: string[]
  }
}

export type UseNavigationPermission = {
  hasPermission: boolean
  hasSomePermission: boolean
  hasSomePermissions: boolean
  hasValidChildren: boolean
  children: ReactNode
}

export type UseNavigationHook = {
  isCollapsed: boolean
}

const cx = classNames.bind(styles)

const Navigation = () => {
  const [activeBlock, setActiveBlock] = useState<Block>('registration')
  const navRef = useRef(null)
  const { isCollapsed } = useNavigation() as UseNavigationHook
  const history = useHistory()

  const reselectBlock = () => {
    Object.entries(routes).forEach(([block, subroutes]: [string, Route]) => {
      if (
        Object.values(subroutes).some(subroute => {
          if (subroute.path) {
            return history.location.pathname.includes(subroute.path)
          }
          return false
        })
      ) {
        setActiveBlock(block as Block)
      }
    })
  }

  useEffect(reselectBlock, [])

  return (
    <nav
      ref={navRef}
      className={cx('navigation', { 'navigation--collapsed': isCollapsed })}
      onMouseLeave={reselectBlock}
    >
      <div className={styles.navigation__container}>
        <div className={styles.navigation__main}>
          <ul className={styles.navigation__main__items}>
            <NavigationBlock
              IconComponent={Icon.BoxBallot}
              label="Registration"
              routes={routes.registration}
              active={activeBlock === 'registration'}
              onSelect={() => {
                setActiveBlock('registration')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.UserMagnifyingGlass}
              label="Quality Control"
              routes={routes['quality control']}
              active={activeBlock === 'quality control'}
              onSelect={() => {
                setActiveBlock('quality control')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.Users}
              label="Organize"
              routes={routes.organize}
              active={activeBlock === 'organize'}
              onSelect={() => {
                setActiveBlock('organize')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.ClipboardList}
              label="Petitions"
              routes={routes.petitions}
              active={activeBlock === 'petitions'}
              onSelect={() => setActiveBlock('petitions')}
            />
          </ul>
        </div>
        <div className={styles.navigation__secondary}>
          <Font.Copy
            variant="hint"
            className={styles['navigation__secondary__block-label']}
          >
            {activeBlock}
          </Font.Copy>
          <ul className={styles.navigation__secondary__items}>
            {Object.entries(routes[activeBlock])
              .filter(([itemKey]) => itemKey !== '_meta')
              .map(([itemKey, params]) => (
                <NavigationItem key={itemKey} label={itemKey} {...params} />
              ))}
          </ul>
        </div>
      </div>
      <div
        className={cx('global-items', {
          'global-items--collapsed': isCollapsed,
        })}
      >
        <LanguageToggle />
        <UserMenu />
      </div>
    </nav>
  )
}

export default Navigation
