import moment from 'moment'
import {
  READY_FOR_PHONE_VERIFICATION,
  IN_PHONE_VERIFICATION,
  COMPLETED,
} from 'constants/qualityControl'
import { formatUTCAsTimeFromNow } from 'utils/dateTime'
import { uniqBy } from 'lodash'

const getScanNumbersText = ({ triggered_by_forms }) =>
  triggered_by_forms.reduce(
    (scanNumbersText, { scan_number }) =>
      scanNumbersText.length === 0
        ? `Scan ${scan_number}`
        : `${scanNumbersText}, Scan ${scan_number}`,
    ''
  )

const getCanvasserName = ({ canvasser }) => canvasser.full_name
const getPacketName = ({ packet }) => packet.original_filename
const getTimeSinceFlagged = ({ created_at }, user) =>
  `Flagged ${formatUTCAsTimeFromNow(created_at, user)}`

const getTimeSinceMostRecentComment = (flag, user) => {
  const mostRecentComment = flag.comments.reduce((mostRecent, comment) =>
    moment(mostRecent.created_at).isAfter(moment(comment.created_at))
      ? mostRecent
      : comment
  )

  return formatUTCAsTimeFromNow(mostRecentComment.created_at, user)
}

export const doesFlagHaveUnreadComments = (flag, { email }) => {
  const { comments } = flag
  return comments.some(
    ({ viewed_by_users }) =>
      !viewed_by_users.some(viewed_by_user => viewed_by_user.email === email)
  )
}

const getTimeSinceNewComment = (flag, user) =>
  doesFlagHaveUnreadComments(flag, user)
    ? `- New comment ${getTimeSinceMostRecentComment(flag, user)}`
    : ''

const getCanvasserSecondaryText = (flag, user) =>
  `${getCanvasserName(flag)} - ${getTimeSinceFlagged(
    flag,
    user
  )} ${getTimeSinceNewComment(flag, user)}`

const getPacketSecondaryText = (flag, user) =>
  `${getPacketName(flag)} - ${getScanNumbersText(flag)} - ${getTimeSinceFlagged(
    flag,
    user
  )} ${getTimeSinceNewComment(flag, user)}`

export const getFlagItemPrimaryText = flag => {
  if (flag?.trigger?.id) {
    return `${flag.id} - ${flag.trigger.name}`
  }
  return ''
}

export const getFlagItemSecondaryText = (flag, user) => {
  const isCanvasserFlag = flag.canvasser !== null
  return isCanvasserFlag
    ? getCanvasserSecondaryText(flag, user)
    : getPacketSecondaryText(flag, user)
}

export const getFlagItemReadStatus = ({ viewed_by_users }, userEmail) =>
  viewed_by_users.some(user => user.email === userEmail)

export const getFlagItemPrimaryIcon = ({ trigger, status }) =>
  trigger.needs_reupload && status !== 'completed' ? 'cloud_upload' : 'flag'

export const isPacketFlag = flag => flag.packet !== null

export const didPacketFlagCompleteVisualReview = flag =>
  [READY_FOR_PHONE_VERIFICATION, IN_PHONE_VERIFICATION, COMPLETED].includes(
    flag.shift.status
  )

export const didPacketFlagCompletePhoneVerification = flag =>
  [COMPLETED].includes(flag.shift.status)

export const getReadyButtonText = flagViewType => {
  switch (flagViewType) {
    case 'start': {
      return 'Send flag to group'
    }
    case 'complete': {
      return 'Mark as resolved'
    }
    case 'reopen': {
      return 'Restore'
    }
    default: {
      return 'Ready'
    }
  }
}

export const getReadyButtonSubText = flagViewType => {
  switch (flagViewType) {
    case 'start': {
      return 'Ready for Group QC Manager action'
    }
    case 'review': {
      return 'Ready for resolution'
    }
    case 'complete': {
      return ''
    }
    case 'reopen': {
      return 'Reopen flag for Table QC review'
    }
    default: {
      return ''
    }
  }
}

export const getBulkReviewButtonText = flagViewType => {
  switch (flagViewType) {
    case 'start': {
      return 'Send all to group'
    }
    default: {
      return 'Review selected'
    }
  }
}

export const canFlagBeAdvanced = (flag, flagViewType, needsReupload) => {
  switch (flagViewType) {
    case 'start': {
      return flag.status === 'pending'
    }
    case 'review': {
      return flag.status === 'ready' && !needsReupload
    }
    case 'complete': {
      return flag.status === 'reviewed'
    }
    case 'reopen': {
      return flag.status === 'completed' || flag.status === 'reviewed'
    }
    default: {
      return false
    }
  }
}

export const isFlagTechnical = flag => !flag.trigger?.implies_canvasser_issue

export const getFlagPackets = flag =>
  uniqBy(
    flag.triggered_by_forms
      .map(scan => scan.packet)
      .concat(flag.packet ? [flag.packet] : []),
    'id'
  )

export const getFlagScans = flag =>
  flag.triggered_by_forms.sort((a, b) => a.id - b.id)
