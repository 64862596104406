/* eslint-disable react/jsx-props-no-spreading */
import { Switch, Route, Redirect } from 'react-router-dom'
import { CurrentProvider, LocaleProvider, TurfsProvider } from 'contexts'
import NotFound from 'layout/NotFound/NotFound'
import { NavigationProvider } from 'layout/NavigationProvider/NavigationProvider'
import AdminSettingRoutes from 'adminSettings/AdminSettingsRoutes'
import Intercom from './Intercom'
import PersonRoutes from './people/PersonRoutes/PersonRoutes'
import TeamRoutes from './teams/TeamRoutes/TeamRoutes'
import CanvasserRoutes from './canvassers/CanvasserRoutes/CanvasserRoutes'
import DeliveryRoutes from './deliveries/DeliveryRoutes/DeliveryRoutes'
import Dashboard from './dashboard/Dashboard/Dashboard'
import PhoneBankRoutes from './phone_banks/PhoneBankRoutes/PhoneBankRoutes'
import EventRoutes from './events/EventRoutes/EventRoutes'
import MeetingRoutes from './events/MeetingRoutes/MeetingRoutes'
import ShiftPacketInformation from './shifts/ShiftPacketInformation'
import ShiftSelect from './shifts/ShiftSelect/ShiftSelect'
import ShiftCreate from './shifts/ShiftCreate/ShiftCreate'
import ShiftEdit from './shifts/ShiftEdit/ShiftEdit'
import FormsSelect from './registration_forms/FormsSelect'
import OrganizationRoutes from './organizations/OrganizationRoutes/OrganizationRoutes'
import ScanUpload from './shifts/ScanUpload'
import ScanSingle from './shifts/ScanSingle'
import RegistrationReports from './registrationReports/RegistrationReports'
import QualityControlReports from './qualityControlReports/QualityControlReports'
import QualityControl from './qualityControl'
import LocationRoutes from './locations/LocationRoutes/LocationRoutes'
import ListRoutes from './lists/ListRoutes/ListRoutes'
import DocumentRoutes from './documents/DocumentRoutes/DocumentRoutes'
import UserSettingRoutes from './userSettings/UserSettingRoutes/UserSettingRoutes'
import OrganizingReports from './organizingReports/OrganizingReports/OrganizingReports'
import PhoneBankReports from './phoneBankReports/PhoneBankReports/PhoneBankReports'
import EmailRoutes from './email/EmailRoutes/EmailRoutes'
import DuplicatePreventionRoutes from './duplicatePrevention/DuplicatePreventionRoutes/DuplicatePreventionRoutes'
import FieldManagementRoutes from './fieldManagement/FieldManagementRoutes/FieldManagementRoutes'
import CheckinRoutes from './checkins/CheckinRoutes/CheckinRoutes'
import ApplicationFrame from './layout/ApplicationFrame/ApplicationFrame'
import ShiftPacket from './shifts/ShiftPacket'
import PetitionReports from './petitionReports/PetitionReports/PetitionReports'
import ScheduledExports from './dataSources/scheduledExports'
import ImportsUpload from './Imports/ImportsUpload'
import Imports from './Imports/Imports'
import ShiftDataEntry from './shifts/ShiftDataEntry/ShiftDataEntry'
import DataEntryEdit from './shifts/ShiftDataEntry/DataEdit'
import CatalistReports from './catalistReports/CatalistReports'

const AuthenticatedApp = props => (
  <CurrentProvider>
    <TurfsProvider>
      <LocaleProvider>
        <Intercom />
        <NavigationProvider>
          <ApplicationFrame>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/auth">
                <Redirect to="/" />
              </Route>
              <Route path="/user_settings" component={UserSettingRoutes} />
              <Route path="/admin" component={AdminSettingRoutes} />
              <Route path="/organize/phone_banks" component={PhoneBankRoutes} />
              <Route path="/email" component={EmailRoutes} />
              <Route path="/organize/people/" component={PersonRoutes} />
              <Route path="/organize/lists" component={ListRoutes} />
              <Route
                path="/organize/organizations"
                component={OrganizationRoutes}
              />
              <Route path="/organize/teams" component={TeamRoutes} />
              <Route
                path="/collect/voter_registration/registration_forms"
                render={routeProps => <FormsSelect {...routeProps} />}
              />
              <Route
                path="/collect/voter_registration/deliveries"
                component={DeliveryRoutes}
              />
              <Route
                path="/collect/:shiftType/canvassers"
                component={CanvasserRoutes}
              />
              <Route
                path="/collect/:shiftType/locations"
                component={LocationRoutes}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts"
                component={ShiftSelect}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts/:id/packet_information"
                component={ShiftPacketInformation}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts/new"
                component={ShiftCreate}
              />
              <Route
                path="/collect/:shiftType/shifts/:id/edit"
                component={ShiftEdit}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts/:id/forms"
                render={routeProps => (
                  <ScanUpload {...routeProps} createScan={props.createScan} />
                )}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts/:id/packet/new"
                render={routeProps => (
                  <ScanUpload {...routeProps} createScan={props.createScan} />
                )}
              />
              <Route
                exact
                path="/collect/:shiftType/shifts/:shiftId/forms/:id"
                render={routeProps => <ScanSingle {...routeProps} />}
              />
              <Route
                path="/collect/:shiftType/shifts/:id/packet"
                component={ShiftPacket}
              />
              <Route
                path="/collect/voter_registration/shifts/:shiftId/data_entry/:pageId"
                component={ShiftDataEntry}
              />
              <Route
                path="/collect/voter_registration/shifts/:shiftId/edit_form_data/:formId"
                component={DataEntryEdit}
              />
              <Route
                path="/organize/event_locations"
                component={LocationRoutes}
              />
              <Route path="/build/events" component={EventRoutes} />
              <Route path="/build/meetings" component={MeetingRoutes} />
              <Route
                path="/reporting/reports/registration_reports"
                component={RegistrationReports}
              />
              <Route
                path="/reporting/reports/quality_control"
                component={QualityControlReports}
              />
              <Route
                path="/reporting/reports/organizing_reports"
                component={OrganizingReports}
              />
              <Route
                path="/reporting/reports/phone_bank_reports"
                component={PhoneBankReports}
              />
              <Route
                path="/reporting/reports/petition_reports"
                component={PetitionReports}
              />
              <Route
                path="/reporting/reports/catalist_reports"
                component={CatalistReports}
              />
              <Route path="/share/documents" component={DocumentRoutes} />
              <Route
                path="/data_sources/imports/:importId/duplicate_prevention"
                component={DuplicatePreventionRoutes}
              />
              <Route
                path="/data_sources/scheduled_exports"
                component={ScheduledExports}
              />
              <Route exact path="/data_sources/imports" component={Imports} />
              <Route
                exact
                path="/data_sources/imports/new"
                component={ImportsUpload}
              />
              <Route
                path="/quality_control"
                render={routeProps => <QualityControl {...routeProps} />}
              />
              <Route
                path="/field-management"
                component={FieldManagementRoutes}
              />
              <Route path="/check-ins" component={CheckinRoutes} />
              <Route path="*" component={NotFound} />
            </Switch>
          </ApplicationFrame>
        </NavigationProvider>
      </LocaleProvider>
    </TurfsProvider>
  </CurrentProvider>
)

export default AuthenticatedApp
