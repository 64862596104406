import moment from 'moment-timezone'
import humanizeString from 'humanize-string'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { READY_FOR_QC } from 'constants/qualityControl'
import { uniq } from 'lodash'
import { Link } from 'react-router-dom'
import { createElement } from 'react'
import { useCurrent } from 'contexts/index'
import { correctInQC } from '../utils'
import { DataEntryFormStorage, DataEntryUploadStorage } from '../storageUtils'

const READY_FOR_SCANS = 'ready_for_scans'

export const ShiftPacketCell = ({ rowData }) => {
  if (rowData.batch_processing) {
    return <TableGridCell>Upload processing</TableGridCell>
  }

  const packet = rowData.packet || rowData.petition_packet || {}
  const petitionPacketFinishedOCR =
    rowData.petition_packet && rowData.petition_packet.signatures.length > 0
  const packetName =
    rowData.packet || petitionPacketFinishedOCR ? (
      <Link to={`shifts/${rowData.id}/packet`}>
        {packet.original_filename || packet.filename}
      </Link>
    ) : (
      packet.filename
    )

  return <TableGridCell>{packetName}</TableGridCell>
}

export const ShiftPacketCountyCell = ({ rowData }) => (
  <TableGridCell>{rowData.petition_packet?.county}</TableGridCell>
)

export const ShiftTurfNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.turf.name}</TableGridCell>
)

export const QCStatusCell = ({ rowData }) => {
  const haveScansBeenUploaded = DataEntryUploadStorage.isShiftInStorage(
    rowData.id
  )

  const hasCoverSheetBeenAdded = !!DataEntryFormStorage.getRecordFromStorage(
    rowData.id,
    0
  ).formData

  const petitionPacket = rowData.petition_packet
  const isPetitionWithoutSignatures =
    petitionPacket && !petitionPacket.signatures.length

  let status = ''

  if (rowData.status === READY_FOR_SCANS && haveScansBeenUploaded) {
    if (!hasCoverSheetBeenAdded) {
      status = 'ready_for_cover_sheet_info'
    }
    if (hasCoverSheetBeenAdded) {
      status = 'in_data_entry'
    }
  }

  if (rowData.status === READY_FOR_QC && isPetitionWithoutSignatures) {
    status = 'waiting_for_OCR'
  }

  status = status || correctInQC(rowData.status)

  return <TableGridCell>{humanizeString(status)}</TableGridCell>
}

export const DeliveryStatusCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.delivered ? 'Delivered' : 'Not Delivered'}
  </TableGridCell>
)

export const ShiftLocationNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.location.name}</TableGridCell>
)

export const ShiftCanvasserNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/collect/voter_registration/canvassers/${rowData.canvasser.id}`}>
      {rowData.canvasser.full_name}
    </Link>
  </TableGridCell>
)

export const AssigneeNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.petition_packet?.assignee?.full_name}</TableGridCell>
)

export const ShiftCampaignNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.campaign?.name}</TableGridCell>
)

export const ShiftStartCell = ({ cellData }) => (
  <TableGridCell>{moment(cellData).format('lll')}</TableGridCell>
)

const ShiftPacketUploadDateCell = ({ rowData }) => {
  const { currentUser } = useCurrent()
  const timezone = currentUser.time_zone
  const packet = rowData.packet || rowData.petition_packet
  const date = packet?.created_at

  return (
    <TableGridCell>
      {date && moment.tz(date, 'UTC').tz(timezone).format('lll')}
    </TableGridCell>
  )
}

export const shiftPacketUploadDateCellRenderer = ({ rowData }) =>
  createElement(ShiftPacketUploadDateCell, { rowData })

export const ShiftDueDateCell = ({ rowData }) => (
  <TableGridCell>
    {rowData?.packet?.qc_deadline &&
      moment(rowData.packet.qc_deadline).format('lll')}
  </TableGridCell>
)

export const ShiftVRRCell = ({ rowData }) => (
  <TableGridCell>
    {uniq(
      rowData.visual_review_responses.map(response => response.description)
    ).join(', ')}
  </TableGridCell>
)
