import { buildSortedData } from 'components/DataTable/utils'

const getMetricCategories = metricVar => {
  switch (metricVar) {
    case 'race':
      return [
        'asian',
        'black',
        'hispanic',
        'nativeAmerican',
        'caucasian',
        'other',
        'unknown',
      ]
    case 'voter_status':
      return [
        'active',
        'dropped',
        'inactive',
        'multipleAppearances',
        'unmatchedMember',
        'unregistered',
        '',
      ]
  }
}

const getMetricVar = word => {
  switch (word) {
    case 'poc':
      return 'race'
    case 'matched':
      return 'voter_status'
    default:
      return ''
  }
}

export const getMetricGroupAndLabel = chartId => {
  const words = chartId.split('_')
  const metricVar = getMetricVar(words[0])
  const groupVar = `${words.pop()}_name`
  return [metricVar, groupVar, words[0]]
}

export const formatTextforLabels = text => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const roundedPercent = (num, denom) => ((num * 100) / denom).toFixed(2)

export const prepareChartData = (chartData, metricVar, groupVar, labelVar) => {
  const groupIdentifier = chartData[0].table_id ? 'table_id' : groupVar
  const groups = chartData.map(row => row[groupIdentifier])
  const metricCategories = getMetricCategories(metricVar)

  const wideData = [...new Set(groups)].map(group => {
    const groupRows = chartData.filter(row => row[groupIdentifier] === group)
    const total = groupRows.reduce((acc, row) => acc + row.count, 0)
    const groupName = groupRows[0][groupVar]

    return metricCategories.reduce(
      (acc, value) => {
        const row = groupRows.find(row => row[metricVar] === value)

        acc[value] = row?.count ? roundedPercent(row.count, total) : 0
        acc.label_count =
          row && row[labelVar] ? acc.label_count + row.count : acc.label_count
        acc.label_percent =
          row && row[labelVar]
            ? roundedPercent(acc.label_count, total)
            : acc.label_percent

        return acc
      },
      { group: groupName, total, label_count: 0 }
    )
  })

  return buildSortedData(wideData, 'label_percent', 'asc', true).slice(0, 10)
}

const getColorPalette = metricVar => {
  switch (metricVar) {
    case 'race':
      return [
        '#004D40',
        '#00796B',
        '#26A69A',
        '#A5D6A7',
        '#FFA726',
        '#F57C00',
        '#E65100',
      ]
    case 'voter_status':
      return [
        '#004D40',
        '#00796B',
        '#26A69A',
        '#66BEA1',
        '#A5D6A7',
        '#C1E3C3',
        '#F57C00',
      ]
  }
}

export const getPlotBars = metricVar => {
  const palette = getColorPalette(metricVar)

  return getMetricCategories(metricVar).map((category, index) => ({
    dataKey: category,
    fill: palette[index],
    name: category === '' ? 'Missing' : formatTextforLabels(category),
  }))
}
