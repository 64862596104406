import { sumBy } from 'lodash'
import {
  EligiblePacket,
  EligiblePacketScan,
  Delivery,
} from '../DeliveryContext'

export function getAssembledCount(assembledPacketIds: Array<string>): number {
  return assembledPacketIds.length
}

export function getEligibleCount(packets: Array<EligiblePacket>): number {
  return sumBy(packets, packet => packet.forms.length)
}

export function getIneligibleCount(
  ineligibleLookupCodes: Array<string>
): number {
  return ineligibleLookupCodes.length
}

export function getIncludedCounties(delivery: Delivery): string[] {
  return delivery.form_filter_counties
}

export function getCompletedPercent(assembled: number, total: number) {
  if (!assembled || !total) return 1
  return Math.round((assembled / total) * 100)
}

export function getIneligibleType(
  delivery: Delivery,
  form: EligiblePacketScan
) {
  if (
    form.visual_review_responses?.some(response => response.implies_not_form)
  ) {
    return 'Invalid QC response'
  }
  if (form.delivery_id) {
    return 'Ineligible form'
  }
  if (!delivery.form_filter_counties?.includes(form.county)) {
    return 'Ineligible form'
  }
}

export function getIneligibleReason(
  delivery: Delivery,
  form: EligiblePacketScan
) {
  if (
    form.visual_review_responses?.some(response => response.implies_not_form)
  ) {
    return 'Not a form'
  }
  if (form.delivery_id) {
    return 'Already in delivery'
  }
  if (!delivery.form_filter_counties?.includes(form.county)) {
    return 'Wrong county'
  }
}
