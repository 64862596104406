import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Font,
  Icon,
  List,
  ProgressBar,
  SectionLabel,
  Sheet,
  TextBlock,
  useToast,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import RequestException from 'utils/RequestException'
import { getEligibility } from 'requests/deliveries'
import { FormWithPacketInfo } from './types'
import styles from './IneligibleList.module.scss'
import { getIneligibleReason, getIneligibleType } from './utils'
import { useDeliveryState } from '../DeliveryContext'

const LoadingItem = ({ lookupCode }: { lookupCode: string }) => {
  const { t } = useTranslation()
  return (
    <List.Item>
      <ContentBlock>
        <Font.Label variant="detail">
          {t('Searching for lookup code {{ lookupCode }}', { lookupCode })}
        </Font.Label>
        <ProgressBar show />
      </ContentBlock>
    </List.Item>
  )
}

const UnknownFormItem = ({ lookupCode }: { lookupCode: string }) => {
  const { t } = useTranslation()
  return (
    <List.Item>
      <ContentBlock>
        <Font.Copy>{t('Unknown form')}</Font.Copy>
        <Font.Copy variant="hint">
          {t('Lookup code {{ lookupCode }}', { lookupCode })}
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy>{t('Not found')}</Font.Copy>
      </ContentBlock>
    </List.Item>
  )
}

const KnownFormItem = ({ form }: { form: FormWithPacketInfo }) => {
  const { currentDelivery } = useDeliveryState()
  const ineligibleType = getIneligibleType(currentDelivery, form)
  const ineligibleReason = getIneligibleReason(currentDelivery, form)

  return (
    <List.Item>
      <ContentBlock>
        <Font.Copy>{`Scan ${form.scan_number} - ${form.display_name}`}</Font.Copy>
        <Font.Copy variant="hint">{form.packet.scan_name}</Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy>{ineligibleType}</Font.Copy>
        <Font.Copy variant="hint">{ineligibleReason}</Font.Copy>
      </ContentBlock>
    </List.Item>
  )
}

const IneligibleItem = ({ item }: { item: Record<string, unknown> }) => {
  const { t } = useTranslation()
  const { setToast } = useToast()

  const [form, setForm] = useState<FormWithPacketInfo | undefined>()
  const getFormRequest = useRequest<{
    meta: {
      is_eligible: boolean
    }
    registration_forms: Array<
      FormWithPacketInfo & {
        packet: { original_filename: string }
      }
    >
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }>(getEligibility, {
    onSuccess: ({ registration_forms }) => {
      const foundForm = registration_forms.pop()
      if (foundForm) {
        setForm({
          ...foundForm,
          packet: {
            ...foundForm.packet,
            scan_name: foundForm.packet.original_filename,
          },
        })
      }
    },
    onError: e => {
      if (e instanceof RequestException && e?.status === 404) {
        return
      }
      setToast({
        message: t('An issue occured while looking up an ineligible form'),
        variant: 'error',
      })
    },
  })

  const lookupCode = item.value as string

  useEffect(() => {
    void getFormRequest.makeRequest({
      fields: [
        'id',
        'scan_number',
        'county',
        'delivery_id',
        'display_name',
        'notes',
        'lookup_code',
        'created_at',
        { phone_verification_responses: ['notes'] },
        { visual_review_responses: ['implies_not_form'] },
        { flags: ['status'] },
        { shift: ['id'] },
        {
          packet: [
            'id',
            'original_filename',
            { shift: ['id', 'shift_start', 'shift_end', 'status'] },
          ],
        },
      ],
      filters: {
        rules: [
          {
            column: 'lookup_code',
            operator: 'is',
            param: lookupCode,
          },
        ],
      },
    })
  }, [lookupCode])

  if (getFormRequest.isLoading) return <LoadingItem lookupCode={lookupCode} />

  if (!form) return <UnknownFormItem lookupCode={lookupCode} />

  return <KnownFormItem form={form} />
}

const BASE_LIST_COUNT = 4

const IneligibleList = ({ ineligible }: { ineligible: string[] }) => {
  const { t } = useTranslation()
  const [count, setCount] = useState(BASE_LIST_COUNT)

  const shownItems = ineligible.slice(0, count).map(id => ({ id, value: id }))

  const showMoreItems = () => {
    setCount(current => current + BASE_LIST_COUNT)
  }

  const isShowMoreButtonActive =
    ineligible.length > 4 && ineligible.length > count

  return (
    <>
      <TextBlock>
        <SectionLabel>
          <span className={styles.title}>{t('Ineligible forms')}</span>
        </SectionLabel>
      </TextBlock>
      <ContentBlock>
        <Sheet>
          <List
            itemData={shownItems}
            emptyMessage={t('No forms found')}
            loading={false}
            render={item => <IneligibleItem item={item} />}
          />
          <ButtonBlock justify="center">
            {!!isShowMoreButtonActive && (
              <Button.Secondary onClick={showMoreItems}>
                <span>{t('Show more')}</span>
                <Icon.ChevronDown />
              </Button.Secondary>
            )}
          </ButtonBlock>
        </Sheet>
      </ContentBlock>
    </>
  )
}

export default IneligibleList
