import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Font } from '@politechdev/blocks-design-system'
import { useNavigationPermission } from 'layout/NavigationPermissionProvider/NavigationPermissionProvider'
import { Permission, UseNavigationPermission } from './Navigation'
import styles from './Navigation.module.scss'

type NavigationItemProps = {
  label: string
  path?: string
  blocks: string[]
  permissions?: Permission[]
}

const cx = classNames.bind(styles)

const NavigationItem = ({
  label,
  path,
  blocks,
  permissions,
}: NavigationItemProps) => {
  const { t } = useTranslation()

  const { hasPermission, hasSomePermission } = useNavigationPermission({
    blocks,
    permissions,
    children: null,
  }) as UseNavigationPermission

  if (
    [
      'registration reports',
      'phone banking reports',
      'quality control reports',
      'field management',
      'petition_reports',
    ].includes(label)
  ) {
    if (!hasSomePermission || !path) return null
  } else if (!hasPermission || !path) return null

  return (
    <li className={cx('navigation__secondary__item')} key={label}>
      <NavLink
        activeClassName={cx('navigation__secondary__item--active')}
        to={path}
      >
        <Font.Copy
          variant="medium"
          className={styles.navigation__secondary__item__label}
        >
          {t(label)}
        </Font.Copy>
      </NavLink>
    </li>
  )
}

export default NavigationItem
