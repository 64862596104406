import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import {
  REPORT_PERMISSION_DATA,
  FIELD_MANAGEMENT_PERMISSION_DATA,
} from 'constants/roles'
import { reportCategories as regReportCategories } from 'registrationReports/reportsConfig'
import { reportCategories as qcReportCategories } from 'qualityControlReports/reportsConfig'
import { reportCategories as petitionReportCategories } from 'petitionReports/configs'
import { useCurrent } from 'contexts/index'
import { defaultTo } from 'lodash'
import RoleDetailHeader from '../RoleDetailHeader/RoleDetailHeader'
import RolePermissions from '../RolePermissions/RolePermissions'
import styles from './RoleDetail.module.scss'
import ReportSection from '../ReportSection/ReportSection'
import RoleSection from '../RoleSection/RoleSection'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import { RoleContext } from '../RoleContext/RoleContext'

const RoleDetail = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const {
    currentRole,
    currentRoleParent: parentRole,
    setCurrentRoleId,
    getRoles,
    getTenantPermissions,
  } = useContext(RoleContext)
  const { makeRequest, isLoading, hasErrors } = useRequest(async id => {
    await getRoles()
    setCurrentRoleId(+id)
  })
  const { searchString } = useContext(RoleAbilityContext)
  const { tenantBlocks } = useCurrent()
  const tenantPermissions = getTenantPermissions(tenantBlocks)

  useEffect(() => {
    makeRequest(match.params.id)
  }, [match.params.id])

  const doesMatchSearch = label =>
    label.toLowerCase().includes(searchString.toLowerCase())

  const isNotReport = id =>
    !REPORT_PERMISSION_DATA.map(data => data.id).includes(id)

  const isNotFieldManagement = id =>
    !FIELD_MANAGEMENT_PERMISSION_DATA.map(data => data.id).includes(id)

  return (
    <div className={styles.content}>
      <LoadBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={t("We're unable to retrieve this role")}
        hideSupportLink
      />
      {currentRole && !hasErrors && (
        <>
          <RoleDetailHeader />
          {doesMatchSearch(t('Registration reports')) && (
            <ReportSection
              reportCategories={regReportCategories}
              title={t('Registration reports')}
            />
          )}
          {doesMatchSearch(t('Quality control reports')) && (
            <ReportSection
              reportCategories={qcReportCategories}
              title={t('Quality control reports')}
            />
          )}
          {doesMatchSearch(t('Weekly reports')) && (
            <ReportSection
              title={t('Weekly reports')}
              reportCategories={[
                {
                  permissionId: 'weekly',
                },
              ]}
            />
          )}
          {doesMatchSearch(t('Catalist reports')) && (
            <ReportSection
              title={t('Catalist reports')}
              reportCategories={[
                {
                  permissionId: 'catalist_reports',
                },
              ]}
            />
          )}
          {doesMatchSearch(t('Phone bank reports')) && (
            <ReportSection
              title={t('Phone bank reports')}
              reportCategories={[
                {
                  permissionId: 'phone_banks',
                },
              ]}
            />
          )}
          {doesMatchSearch(t('Petition reports')) && (
            <ReportSection
              title={t('Petition reports')}
              reportCategories={petitionReportCategories}
            />
          )}
          {doesMatchSearch(t('Field management')) && (
            <RoleSection
              title={t('Field management')}
              permissions={tenantPermissions.filter(
                permission => !isNotFieldManagement(permission.id)
              )}
              parentRole={parentRole}
            />
          )}
          {tenantPermissions
            .filter(
              permission =>
                isNotReport(permission.id) &&
                isNotFieldManagement(permission.id) &&
                doesMatchSearch(permission.label)
            )
            .map(permission => (
              <RolePermissions
                key={permission.key || permission.id}
                permission={permission}
                parentAdmin={parentRole.admin}
                parentPermissions={
                  defaultTo(
                    parentRole.permissions.find(
                      p => p.resource === permission.id
                    ),
                    { abilities: [] }
                  ).abilities
                }
              />
            ))}
        </>
      )}
    </div>
  )
}

export default RoleDetail
