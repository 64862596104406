import { FormWithPacketInfo } from './types'

export const ScanCsvCell = ({ rowData }: { rowData: FormWithPacketInfo }) =>
  rowData.scan_number

export const PacketCsvCell = ({ rowData }: { rowData: FormWithPacketInfo }) =>
  rowData.packet.scan_name

export const RegistrantCsvCell = ({
  rowData,
}: {
  rowData: FormWithPacketInfo
}) => rowData.display_name

export const CountyCsvCell = ({ rowData }: { rowData: FormWithPacketInfo }) =>
  rowData.county

export const CollectedCsvCell = ({
  rowData,
}: {
  rowData: FormWithPacketInfo
}) => rowData.packet.shift.shift_start

export const UploadedCsvCell = ({ rowData }: { rowData: FormWithPacketInfo }) =>
  rowData.created_at
