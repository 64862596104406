import { fetchJSON, stringifyParams } from 'utils/req'

const defaultDeliveryOptions = {
  fields: [
    'id',
    'runnder_receipt_url',
    'turn_in_date',
    'status',
    'form_filter_registration_date_start',
    'form_filter_registration_date_end',
    'form_filter_counties',
    'form_filters_no_county',
    'form_filter_qc_statuses',
    'delivery_method',
    'notes',
    'attached_forms_count',
    { excluded_forms: 'id' },
    {
      turn_in_location: [
        'id',
        'city',
        'contact_email',
        'contact_name',
        'contact_phone',
        'county',
        'geoid',
        'has_av',
        'has_hosted_event',
        'has_it_support',
        'has_projector',
        'has_public_transportation',
        'has_wifi',
        'largest_turnout',
        'latitude',
        'location_type',
        'longitude',
        'maximum_occupancy',
        'name',
        'notes',
        'parking_spots_available',
        'rooms_available',
        'score',
        'state',
        'street_address',
        'timezone',
        'zipcode',
      ],
    },
    {
      office: [
        'id',
        'name',
        'lft',
        'rgt',
        'extras',
        'min_phone_verification_percent',
        'min_phone_verification_rounds',
        'phone_verification_language',
        'qc_config',
        'qc_turnaround_days',
        {
          activism_options_config: [
            'id',
            'issues',
            'skills',
            'languages',
            'campaigns',
          ],
        },
      ],
    },
    {
      canvasser: [
        'id',
        'first_name',
        'last_name',
        'full_name',
        'phone_number',
        'notes',
        'email',
        'vdrs',
      ],
    },
  ],
  associations: [
    'canvasser',
    'turn_in_location',
    'office',
    'office.activism_options_config',
  ],
}

export const postDelivery = delivery =>
  fetchJSON(
    `/api/v1/deliveries/?${stringifyParams(defaultDeliveryOptions)}`,
    'POST',
    {
      delivery,
    },
    { useJwt: true }
  )

export const putDelivery = (deliveryId, attrs) =>
  fetchJSON(
    `/api/v1/deliveries/${deliveryId}?${stringifyParams(
      defaultDeliveryOptions
    )}`,
    'PUT',
    {
      delivery: attrs,
    },
    {
      useJwt: true,
    }
  )

export const getDelivery = async id => {
  const params = stringifyParams(defaultDeliveryOptions)

  const deliveryResponse = await fetchJSON(
    `/api/v1/deliveries/${id}?${params}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

  return deliveryResponse
}

export const assembleForm = async (deliveryId, formId) =>
  fetchJSON(
    `/api/v1/forms/${formId}`,
    'PUT',
    {
      form: { delivery_id: deliveryId },
    },
    {
      useJwt: true,
    }
  )

export function getEligibility(params) {
  return fetchJSON(`/api/v1/forms?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })
}
