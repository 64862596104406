import { ReactNode } from 'react'
import {
  ContentBlock,
  SectionLabel,
  Sheet,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useTranslation } from 'react-i18next'
import tableConfig from './assembledTableConfig'

const AssembledTable = ({
  assembled,
  scanByIdMap,
}: {
  assembled: string[]
  scanByIdMap: Record<string, unknown>
}) => {
  const { t } = useTranslation()
  const { renderTable } = useAutoTable() as {
    renderTable: (props: {
      data: Array<unknown>
      columns: typeof tableConfig.columns
      emptyHeight: number
      emptyMessage: string
      loading: boolean
      totalRecords: number
      fetchAction: () => void
    }) => ReactNode
  }

  const tableData = assembled.map(id => scanByIdMap[id])

  return (
    <ContentBlock>
      <Sheet>
        <TextBlock>
          <SectionLabel>{t('Assembled')}</SectionLabel>
        </TextBlock>
        {renderTable({
          fetchAction: () => {},
          data: tableData,
          columns: tableConfig.columns,
          loading: false,
          totalRecords: tableData.length,
          emptyHeight: 200,
          emptyMessage: 'No forms assembled',
        })}
      </Sheet>
    </ContentBlock>
  )
}

export default ({
  assembled,
  scanByIdMap,
}: {
  assembled: string[]
  scanByIdMap: Record<string, unknown>
}) => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    showQuickSearch
    showDownloadButton
  >
    <AssembledTable assembled={assembled} scanByIdMap={scanByIdMap} />
  </AutoTableProvider>
)
