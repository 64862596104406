import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { assembleForm } from 'requests/deliveries'
import AssemblyStats from './ScannerAssembly/AssemblyStats'
import AssemblyMain from './ScannerAssembly/AssemblyMain'
import IneligibleList from './ScannerAssembly/IneligibleList'
import AssembledTable from './ScannerAssembly/AssembledTable'
import { useDeliveryActions, useDeliveryState } from './DeliveryContext'
import { FormWithPacketInfo } from './ScannerAssembly/types'
import { useScannerAssemblyContext } from './ScannerAssembly/ScannerAssemblyContext'

const ScannerAssembly = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { currentDelivery } = useDeliveryState()
  const { assembled, setAssembled, packets } = useScannerAssemblyContext()

  const [ineligible, setIneligible] = useState<string[]>([])

  const lookupToFormIdMap = packets
    .flatMap(packet => packet.forms)
    .reduce(
      (mapping, form) => {
        if (form.lookup_code) {
          mapping[form.lookup_code] = form.id
        }
        return mapping
      },
      {} as Record<string, number>
    )

  const formByIdMap = packets
    .flatMap(packet =>
      packet.forms.map(form => ({
        ...form,
        packet: {
          shift: packet.shift,
          scan_name: packet.scan_name,
          id: packet.id,
        },
      }))
    )
    .reduce(
      (mapping, form) => {
        mapping[form.id.toString()] = form
        return mapping
      },
      {} as Record<string, FormWithPacketInfo>
    )

  const { makePacketsRequest } = useDeliveryActions()

  useEffect(() => {
    if (currentDelivery?.id) {
      void makePacketsRequest(currentDelivery.id)
    }
  }, [currentDelivery?.id])

  useEffect(() => {
    if (currentDelivery) {
      setAssembled((currentDelivery.forms ?? []).map(({ id }) => id.toString()))
    }
  }, [currentDelivery])

  const assembleScanRequest = useRequest<string>(
    assembleForm as (deliveryId: string, formId: number) => Promise<string>,
    {
      onError: () =>
        setToast({
          variant: 'error',
          message: t('Failed to add scan to delivery'),
        }),
    }
  )

  return (
    <>
      <AssemblyStats
        packets={packets}
        assembled={assembled}
        ineligible={ineligible}
      />
      <AssemblyMain
        packets={packets}
        assembled={assembled}
        makeAssembleScanRequest={formId => {
          void assembleScanRequest.makeRequest(currentDelivery.id, formId)
          setAssembled(current => {
            const formIdStr = formId.toString()
            return current.includes(formIdStr)
              ? current
              : current.concat(formIdStr)
          })
        }}
        addIneligible={lookupCode =>
          setIneligible(current =>
            current.includes(lookupCode) ? current : current.concat(lookupCode)
          )
        }
        lookupToFormIdMap={lookupToFormIdMap}
        formByIdMap={formByIdMap}
      />
      <IneligibleList ineligible={ineligible} />
      <AssembledTable assembled={assembled} scanByIdMap={formByIdMap} />
    </>
  )
}

export default ScannerAssembly
