import { FormEvent, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { sortBy } from 'lodash'
import classNames from 'classnames/bind'
import {
  Font,
  Section,
  TextField,
  List,
  ContentBlock,
  TextBlock,
  FieldBlock,
  ProgressBar,
  Icon,
} from '@politechdev/blocks-design-system'
import { EligiblePacket } from '../DeliveryContext'
import { getCompletedPercent } from './utils'
import styles from './AssemblyMain.module.scss'
import PacketIncompleteQCModal from './Modals/IncompleteQCModal'
import { FormWithPacketInfo } from './types'

const cx = classNames.bind(styles)

const SCANNER_STATE = {
  IDLE: 'IDLE',
  READY: 'READY',
} as const

const SCANNER_FEEDBACK = {
  ELIGIBLE: 'ELIGBILE',
  INELIGIBLE: 'INELIGIBLE',
} as const

type ScannerState = (typeof SCANNER_STATE)[keyof typeof SCANNER_STATE]
type ScannerFeedback = (typeof SCANNER_FEEDBACK)[keyof typeof SCANNER_FEEDBACK]
type ScannerStatus = ScannerState | ScannerFeedback

const useScanState = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const [scanState, setScanState] = useState<ScannerState>(SCANNER_STATE.IDLE)
  const [scanFeedback, setScanFeedback] = useState<ScannerFeedback | null>(null)

  const submitFeedback = (newFeedback: ScannerFeedback) => {
    setScanFeedback(newFeedback)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
    timeoutRef.current = setTimeout(() => setScanFeedback(null), 2000)
  }

  return {
    status: scanFeedback || scanState,
    setState: setScanState,
    setFeedback: submitFeedback,
  }
}

const EligiblePacketItem = ({
  packet,
  assembledIdSet,
}: {
  packet: EligiblePacket
  assembledIdSet: Set<string>
}) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const shouldExpand = isExpanded && !!packet.forms

  const countAssembled = packet.forms.filter(form =>
    assembledIdSet.has(`${form.id}`)
  ).length

  const totalScans = packet.forms.length

  const completedPercent = getCompletedPercent(countAssembled, totalScans)

  const sortedForms = sortBy(packet.forms, 'scan_number')

  return (
    <>
      <List.Item>
        <div
          className={styles.expandable}
          onClick={() => setIsExpanded(current => !current)}
        >
          <ContentBlock>
            <Font.Copy>{packet.scan_name}</Font.Copy>
            <Font.Copy variant="hint">
              {t(`{{countAssembled}} of {{totalScans}} assembled`, {
                countAssembled,
                totalScans,
              })}
            </Font.Copy>
          </ContentBlock>
          <ProgressBar show progress={completedPercent} />
        </div>
      </List.Item>
      {shouldExpand && (
        <div className={styles.sublist}>
          <ul className={styles.sublist__content}>
            {sortedForms.map(form => (
              <List.Item key={form.id}>
                <ContentBlock>
                  <Font.Copy>{`${t('Scan')} ${form.scan_number} - ${form.display_name}`}</Font.Copy>
                  <Font.Copy variant="hint">
                    <span>
                      {assembledIdSet.has(`${form.id}`)
                        ? t('Assembled')
                        : t('Pending')}
                    </span>
                    {assembledIdSet.has(`${form.id}`) && <Icon.Check />}
                  </Font.Copy>
                </ContentBlock>
              </List.Item>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

const AssemblyMain = ({
  packets,
  assembled,
  makeAssembleScanRequest,
  addIneligible,
  lookupToFormIdMap,
  formByIdMap,
}: {
  packets: Array<EligiblePacket>
  assembled: Array<string>
  makeAssembleScanRequest: (scanId: number) => void
  addIneligible: (lookupCode: string) => void
  lookupToFormIdMap: Record<string, number>
  formByIdMap: Record<string, FormWithPacketInfo>
}) => {
  const { t } = useTranslation()
  const scanInputRef = useRef<HTMLElement | null>(null)
  const [lookupCode, setLookupCode] = useState<string>('')
  const [packetSearch, setPacketSearch] = useState('')

  const [isIncompleteQcModalOpen, setIsIncompleteQcModalOpen] = useState(false)
  const [ignoredPackets, setIgnoredPackets] = useState<number[]>([])

  const scanState = useScanState()

  const focusInput = () => {
    if (scanInputRef.current) {
      scanInputRef.current?.focus()
    }
  }

  useLayoutEffect(() => {
    const inputElement = document.querySelector('#scanner-lookup')
    scanInputRef.current = inputElement as HTMLElement
    focusInput()
  }, [])

  const handleCompletedAssembly = (assemblyResult: boolean) => {
    setIsIncompleteQcModalOpen(false)
    setLookupCode('')
    scanState.setFeedback(
      assemblyResult ? SCANNER_FEEDBACK.ELIGIBLE : SCANNER_FEEDBACK.INELIGIBLE
    )
    focusInput()
  }

  const handleLookup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const matchedFormId = lookupToFormIdMap[lookupCode]
    if (matchedFormId) {
      const form = formByIdMap[matchedFormId]
      const isNonCompleteShift = form.packet.shift.status !== 'completed'
      const isIgnoredPacket = ignoredPackets.includes(form.packet.id)
      if (isNonCompleteShift && !isIgnoredPacket) {
        setIsIncompleteQcModalOpen(true)
        return
      }
      makeAssembleScanRequest(matchedFormId)
      handleCompletedAssembly(true)
    } else {
      addIneligible(lookupCode)
      handleCompletedAssembly(false)
    }
  }

  const handleDeliveryPacketWithIncompleteQC = () => {
    const matchedFormId = lookupToFormIdMap[lookupCode]
    makeAssembleScanRequest(matchedFormId)
    handleCompletedAssembly(true)
  }

  const ignoreLookupScanPacket = () => {
    const matchedFormId = lookupToFormIdMap[lookupCode]
    if (matchedFormId) {
      const form = formByIdMap[matchedFormId]
      setIgnoredPackets(current => current.concat(form.packet.id))
    }
  }

  const assembledIdSet = new Set(assembled)

  const focusHandlers = {
    onFocus: () => {
      scanState.setState(SCANNER_STATE.READY)
    },
    onBlur: () => {
      scanState.setState(SCANNER_STATE.IDLE)
    },
  }

  const filteredPackets = packets.filter(packet =>
    packet.scan_name.includes(packetSearch)
  )

  return (
    <Section label={t('Eligible forms')}>
      <div className={styles.main}>
        <div className={styles.leftpanel}>
          <ContentBlock className={styles.stickyblock}>
            <TextField
              id="packet-search"
              aria-label="Search packets"
              type="search"
              icon={undefined}
              onChange={value => setPacketSearch(value as string)}
            />
          </ContentBlock>
          <List
            itemData={filteredPackets}
            emptyMessage={t('No forms found')}
            loading={false}
            render={item => {
              const packet = item as EligiblePacket
              const key = packet.id
              return (
                <EligiblePacketItem
                  key={key}
                  packet={packet}
                  assembledIdSet={assembledIdSet}
                />
              )
            }}
          />
        </div>
        <div className={styles.rightpanel}>
          <TextBlock>
            {scanState.status === SCANNER_STATE.IDLE && (
              <Font.Display>{t('Not ready for scanning')}</Font.Display>
            )}
            {scanState.status === SCANNER_STATE.READY && (
              <Font.Display>{t('Ready for scanning lookup code')}</Font.Display>
            )}
            {(Object.values(SCANNER_FEEDBACK) as ScannerStatus[]).includes(
              scanState.status
            ) && <Font.Display>{t('Processed')}</Font.Display>}
          </TextBlock>
          <FieldBlock>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={handleLookup}>
              <TextField
                id="scanner-lookup"
                label={t('Lookup code')}
                icon={undefined}
                onChange={value => setLookupCode(value as string)}
                value={lookupCode}
                hint={
                  scanState.status === SCANNER_STATE.IDLE
                    ? t('Field is not focused')
                    : undefined
                }
                {...focusHandlers}
              />
            </form>
          </FieldBlock>
          {scanState.status === SCANNER_STATE.IDLE && (
            <ContentBlock
              className={cx(
                'scanner__feedback__block',
                'scanner__feedback--negative'
              )}
            >
              <Font.Display variant="small">
                <span className={cx('scanner__feedback--negative')}>
                  {t('Focus field to scan')}
                </span>
              </Font.Display>
              <Icon.Ban
                className={cx(
                  'scanner__feedback__icon',
                  'scanner__feedback--negative'
                )}
              />
            </ContentBlock>
          )}
          {scanState.status === SCANNER_STATE.READY && (
            <ContentBlock className={cx('scanner__feedback__block')}>
              <Icon.Ellipsis className={cx('scanner__feedback__icon')} />
            </ContentBlock>
          )}
          {scanState.status === SCANNER_FEEDBACK.ELIGIBLE && (
            <ContentBlock
              className={cx(
                'scanner__feedback__block',
                'scanner__feedback--positive'
              )}
            >
              <Font.Display variant="small">
                <span className={cx('scanner__feedback--positive')}>
                  {t('Assemble form')}
                </span>
              </Font.Display>
              <Icon.Check
                className={cx(
                  'scanner__feedback__icon',
                  'scanner__feedback--positive'
                )}
              />
            </ContentBlock>
          )}
          {scanState.status === SCANNER_FEEDBACK.INELIGIBLE && (
            <ContentBlock
              className={cx(
                'scanner__feedback__block',
                'scanner__feedback--negative'
              )}
            >
              <Font.Display variant="small">
                <span className={cx('scanner__feedback--negative')}>
                  {t('Ineligible form')}
                </span>
              </Font.Display>
              <Icon.X
                className={cx(
                  'scanner__feedback__icon',
                  'scanner__feedback--negative'
                )}
              />
            </ContentBlock>
          )}
        </div>
      </div>
      <PacketIncompleteQCModal
        isOpen={isIncompleteQcModalOpen}
        setIsOpen={setIsIncompleteQcModalOpen}
        handleIgnore={ignoreLookupScanPacket}
        handleContinue={handleDeliveryPacketWithIncompleteQC}
      />
    </Section>
  )
}

export default AssemblyMain
