import { useEffect, useMemo, useState } from 'react'
import { get } from 'lodash'
import { useRouteQueryParams, useReactRouter, useEvent } from 'hooks'
import { useTranslation } from 'react-i18next'
import { READY_FOR_PHONE_VERIFICATION } from 'constants/qualityControl'
import { alphaNumericCompare } from 'utils/array'
import {
  getIsScanExcludedByPreviousNotContactedResponse,
  getIsScanExcludedByVisualReview,
} from 'qualityControl/contexts/contextUtils'
import { CardError } from 'components/'
import { NOT_CONTACTED_REASON } from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import CallScriptsContextProvider from 'qualityControl/callScripts/CallScriptsContext'
import { Font } from '@politechdev/blocks-design-system'
import {
  useCurrentPacket,
  usePacketActions,
} from 'qualityControl/contexts/PacketContext'
import { useCurrentScan } from 'qualityControl/contexts/ScanContext'
import {
  getCurrentPacketPhoneVerificationComplete,
  getSortedScanIds,
  getVerifyingQuestionId,
} from 'qualityControl/contexts/contextSelectors'
import { usePhoneVerificationQuestions } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import { withReloadableSession } from './ReloadableSession'
import VoipCallPanel from '../callPanels/VoipCallPanel/VoipCallPanel'
import ExternalCallPanel from '../callPanels/ExternalCallPanel/ExternalCallPanel'
import PacketDetails from '../PacketDetails/PacketDetails'
import LogCallControls from '../LogCallControls/LogCallControls'
import CallScript from '../CallScript/CallScript'
import { usePhoneVerificationCall } from '../../usePhoneVerificationCall'
import { ScanPdfViewer } from '../../../PdfViewer'
import ScanHistoryDetails from '../ScanHistoryDetails/ScanHistoryDetails'
import ScanViewerHeader from '../../../ScanViewHeader/ScanViewerHeader'
import RegistrantProfile from '../RegistrantProfile/RegistrantProfile'
import PacketPaginationControls from '../PacketPaginationControls/PacketPaginationControls'
import SubmitPhoneVerificationBanner from '../SubmitForDeliveryBanner/SubmitForDeliveryBanner'
import CompleteQCModal from '../CompleteQCModal/CompleteQCModal'
import styles from './PhoneVerificationCallSession.module.scss'
import { getCallableScansCount, getNextCallableScanIndex } from './utils'

const getInitiallyCallableScanIds = (packet, verifyingId) => {
  const callableScanIds = packet.forms
    .filter(
      form =>
        !getIsScanExcludedByVisualReview(form) &&
        !getIsScanExcludedByPreviousNotContactedResponse(form) &&
        !form.phone_verification_responses.find(
          r => r.phone_verification_question_id === verifyingId
        )
    )
    .map(({ id }) => id)

  callableScanIds.sort(alphaNumericCompare)
  return callableScanIds
}

const PhoneVerificationSession = () => {
  const { t } = useTranslation()

  const [
    currentPacket,
    ,
    {
      isRequestComplete: hasFetchedPacket,
      hasErrors: hasFetchPacketErrors,
      reload,
    },
  ] = useCurrentPacket()
  const [currentScan, setCurrentScanId] = useCurrentScan()
  const [questions, questionsRequest] = usePhoneVerificationQuestions()
  const verifyingId = getVerifyingQuestionId(questions)
  const isPhoneVerificationComplete = getCurrentPacketPhoneVerificationComplete(
    currentPacket,
    questions,
    currentScan
  )
  const allScanIds = getSortedScanIds(currentPacket)
  const { advanceCurrentPacket } = usePacketActions()
  const advanceCurrentPacketToPhoneVerification = () =>
    advanceCurrentPacket(READY_FOR_PHONE_VERIFICATION)

  const {
    lastActiveCallRecordId,
    createCallRecord,
    endCallRecord,
    submitPhoneVerification,
    contactedStatus,
    setContactedStatus,
    verificationResponses,
    setVerificationResponses,
    hasEditedChanges,
    setHasEditedChanges,
    currentCallId,
    callTimer,
  } = usePhoneVerificationCall()

  const { match } = useReactRouter()
  const [queryParams, setQueryParams] = useRouteQueryParams()
  const [sessionScanCallMap, setSessionScanCallMap] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [notesValue, setNotesValue] = useState('')

  const [overrideIsEveryScanCalled, setOverrideIsEveryScanCalled] =
    useState(false)

  const [isDeliveryDeferred, setIsDeliveryDeferred] = useState(false)

  const paramsScanId = +queryParams.scanId
  const initiallyCallableScanIds = useMemo(
    () => getInitiallyCallableScanIds(currentPacket, verifyingId),
    [currentPacket?.id, verifyingId]
  )

  if (
    paramsScanId &&
    queryParams.source === 'flags' &&
    !initiallyCallableScanIds.includes(paramsScanId)
  ) {
    initiallyCallableScanIds.unshift(paramsScanId)
  }

  const [isPortalAvailable, setPortalAvailable] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setPortalAvailable(true)
  }, [])

  useEffect(() => {
    if (currentScan?.id) {
      const phoneNumber =
        get(currentScan, 'pledge_card_metadata.phone_number') ||
        get(currentScan, 'phone_number') ||
        ''

      setPhoneNumber(phoneNumber.replaceAll(/\D/g, ''))
      setHasEditedChanges(false)
    }
  }, [currentScan?.id])

  useEffect(() => {
    if (questions && currentScan?.id) {
      const questionsIdKeyMap = Object.fromEntries(
        Object.values(questions).map(({ key, id }) => [id, key])
      )

      const pvrs = currentScan.phone_verification_responses

      const callId = get(sessionScanCallMap, currentScan.id)
      const responses = pvrs.filter(response => callId === response.call.id)

      if (responses.length) {
        const responseEntries = responses.map(response => [
          response.phone_verification_question_id,
          response.response,
        ])
        setVerificationResponses(Object.fromEntries(responseEntries))

        let contacted = 't'
        responses.forEach(pvr => {
          const questionKey =
            questionsIdKeyMap[pvr.phone_verification_question_id]

          if (questionKey === NOT_CONTACTED_REASON) {
            contacted = 'f'
          }

          if (pvr.notes) {
            setNotesValue(pvr.notes)
          }
        })

        setContactedStatus(contacted)
      }
    }
  }, [questions, currentScan?.id])

  const [lastCallableScanData, setLastCallableScanData] = useState({
    position: 0,
    total: 0,
  })

  useEffect(() => {
    const currentScanIndexInCallableScans =
      initiallyCallableScanIds.indexOf(paramsScanId)
    const doesScanExistInCallableScans = currentScanIndexInCallableScans !== -1

    if (doesScanExistInCallableScans) {
      setLastCallableScanData({
        position: currentScanIndexInCallableScans,
        total: initiallyCallableScanIds.length,
      })
    }
  }, [initiallyCallableScanIds, paramsScanId])

  useEffect(() => {
    if (!initiallyCallableScanIds.length) return

    const firstCallableScanId = initiallyCallableScanIds[0]

    const currentScanIndexInCallableScans =
      initiallyCallableScanIds.indexOf(paramsScanId)
    const isScanInCallableScans = currentScanIndexInCallableScans !== -1

    const shouldPushToFirstScan =
      (!paramsScanId || !isScanInCallableScans) && !!firstCallableScanId

    if (shouldPushToFirstScan) {
      setCurrentScanId(firstCallableScanId)
    }
  }, [paramsScanId, initiallyCallableScanIds])

  useEffect(() => {
    if (currentPacket?.shift.status === READY_FOR_PHONE_VERIFICATION) {
      advanceCurrentPacketToPhoneVerification()
    }
  }, [currentPacket])

  const allScansScanIndex = allScanIds.indexOf(paramsScanId)
  const displayScanIndex = initiallyCallableScanIds
    ? initiallyCallableScanIds.indexOf(paramsScanId)
    : allScansScanIndex

  const currentScanIndexInCallableScans =
    initiallyCallableScanIds.indexOf(paramsScanId)

  const isScanInCallableScans = currentScanIndexInCallableScans !== -1

  const callableScanIndex = !isScanInCallableScans
    ? lastCallableScanData.position
    : currentScanIndexInCallableScans

  const callableScanCount = !isScanInCallableScans
    ? lastCallableScanData.total
    : initiallyCallableScanIds.length

  const changeScanPage = useEvent(delta => {
    const nextIndex = callableScanIndex + delta
    setQueryParams({
      ...queryParams,
      scanId: initiallyCallableScanIds[nextIndex],
    })
  })

  const goToNextPage = () => {
    changeScanPage(1)
  }

  const goToPreviousPage = () => {
    changeScanPage(-1)
  }

  const goToNextAvailableScan = () => {
    const nextCallableScanIndex = getNextCallableScanIndex(
      currentScanIndexInCallableScans,
      initiallyCallableScanIds,
      sessionScanCallMap
    )

    if (nextCallableScanIndex !== null) {
      setCurrentScanId(initiallyCallableScanIds[nextCallableScanIndex])
    }
  }

  const allScansCountText = `${t('Scan')} ${
    (allScanIds.indexOf(paramsScanId) === -1
      ? 0
      : allScanIds.indexOf(paramsScanId)) + 1
  } of ${allScanIds.length} ${t('in packet')}`

  const callableScanCountText = `${t('Scan')} ${
    callableScanIndex + 1
  } of ${callableScanCount} ${t('in call queue')}`

  const isEveryScanCalled = initiallyCallableScanIds.every(
    scanId => !!sessionScanCallMap[scanId]
  )

  const callableScansCount = getCallableScansCount(currentPacket, verifyingId)

  const isSubmittable = callableScansCount === 0 || isPhoneVerificationComplete

  const showCompleteQCModal =
    (isSubmittable && !isDeliveryDeferred) ||
    (!overrideIsEveryScanCalled && isEveryScanCalled)

  const closeIsEveryScanCalled = () => {
    setOverrideIsEveryScanCalled(true)
  }

  const recordingEnabled =
    !!currentPacket.turf.voter_registration_config
      .record_voip_phone_verification_calls

  const hasQuestionsConfigured = !!Object.values(questions || {}).some(
    question => !!question.id
  )

  if (hasFetchPacketErrors) return null
  if (!currentScan) return null

  const showEditingUi = !!sessionScanCallMap[currentScan.id]

  return (
    <div>
      <div>
        <SubmitPhoneVerificationBanner
          show={hasFetchedPacket && isPhoneVerificationComplete}
          currentPacket={currentPacket}
        />
        <div className={styles.session}>
          <div className={styles.sidebar}>
            <RegistrantProfile />
            <ScanViewerHeader
              customTitle={
                <div className={styles['scan-viewer-header']}>
                  <p className={styles['scan-viewer-header__title']}>
                    {callableScanCountText}
                  </p>
                  <p className={styles['scan-viewer-header__aside']}>
                    ({allScansCountText})
                  </p>
                </div>
              }
              scansCount={callableScanCount}
              currentScanIndex={displayScanIndex}
              scanNumber={currentScan.scan_number}
            />
            <ScanPdfViewer
              scanUrl={currentScan.file_url}
              removedAt={currentScan.file_locator?.metadata?.redacted_at}
              isInteractive
            />
            <PacketDetails />
            <ScanHistoryDetails />
          </div>
          <div>
            {match.params.mode === 'voip' && recordingEnabled && (
              <div className={styles.notice}>
                <Font.Copy>{t('Notice: Calls will be recorded')}</Font.Copy>
              </div>
            )}
            <CallScriptsContextProvider>
              <CallScript
                setHasEditedChanges={setHasEditedChanges}
                setContactedStatus={setContactedStatus}
                setVerificationResponses={setVerificationResponses}
                verificationResponses={verificationResponses}
                questions={questions}
                phoneNumber={phoneNumber}
              />
            </CallScriptsContextProvider>
          </div>
          <CompleteQCModal
            show={hasFetchedPacket && showCompleteQCModal}
            isSubmittable={isSubmittable}
            isEveryScanCalled={isEveryScanCalled}
            currentPacket={currentPacket}
            closeIsEveryScanCalled={closeIsEveryScanCalled}
            setIsDeliveryDeferred={setIsDeliveryDeferred}
          />
        </div>
      </div>
      <div className={styles.controls}>
        <div className={styles.dialer__wrapper}>
          <div className={styles.dialer}>
            <CardError
              hide={
                !questionsRequest.isRequestComplete || hasQuestionsConfigured
              }
              message={t("We're unable to retrieve call questions.")}
            />
            {match.params.mode === 'voip' && hasQuestionsConfigured && (
              <VoipCallPanel
                packetId={currentPacket.id}
                recordingEnabled={recordingEnabled}
                isPortalAvailable={isPortalAvailable}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                isEditing={showEditingUi}
                createCallRecord={createCallRecord}
                endCallRecord={endCallRecord}
                currentCallId={currentCallId}
                callTimer={callTimer}
                submitPhoneVerification={() =>
                  submitPhoneVerification.makeRequest(lastActiveCallRecordId)
                }
              />
            )}
            {match.params.mode === 'external' && hasQuestionsConfigured && (
              <ExternalCallPanel
                isPortalAvailable={isPortalAvailable}
                currentPacket={currentPacket}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                isEditing={showEditingUi}
                createCallRecord={createCallRecord}
                endCallRecord={endCallRecord}
                currentCallId={currentCallId}
                callTimer={callTimer}
                submitPhoneVerification={() =>
                  submitPhoneVerification.makeRequest(lastActiveCallRecordId)
                }
              />
            )}
          </div>
          <LogCallControls
            questions={questions}
            isEditing={showEditingUi}
            editingCallId={sessionScanCallMap[currentScan.id]}
            setSessionScanCallMap={setSessionScanCallMap}
            goToNextScan={goToNextAvailableScan}
            phoneNumber={phoneNumber}
            notesValue={notesValue}
            setNotesValue={setNotesValue}
            hasEditedChanges={hasEditedChanges}
            setOverrideIsEveryScanCalled={setOverrideIsEveryScanCalled}
            getPacket={reload}
            setContactedStatus={setContactedStatus}
            contactedStatus={contactedStatus}
            setVerificationResponses={setVerificationResponses}
            verificationResponses={verificationResponses}
            createCallRecord={createCallRecord}
            endCallRecord={endCallRecord}
            submitPhoneVerification={submitPhoneVerification}
            lastActiveCallRecordId={lastActiveCallRecordId}
          />
        </div>
        <div className={styles.footer}>
          <div
            id="pv__call_panel_call_actions_portal_target"
            className={styles.footer__pagination}
          />
          <PacketPaginationControls
            currentScanIndex={callableScanIndex}
            scansCount={callableScanCount}
            goToNext={goToNextPage}
            goToPrevious={goToPreviousPage}
            callQueueAllScansCountText={allScansCountText}
          />
        </div>
      </div>
    </div>
  )
}

export default withReloadableSession(PhoneVerificationSession)
